import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import thanksPagesStore from "../../../store/thanks-page-store";

const ThanksPage = () => {
  const { data } = useAppSelector(state => state.thanksPages.thanksPagesData);
  const style = useStyles();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(thanksPagesStore.actions.fetchThanksPages());
  }, []);

  return (
    <div>
    {window.location.href.includes("phone") ? (
      <>
        <div className={style.contentPageContainer}>{data[0]?.titlePhone}</div>
        <div className={style.title}>{data[0]?.subTitlePhone.replace("<p>", "").replace("</p>", "")}</div>
      </>
    ) : window.location.href.includes("card") ? (
      <>
        <div className={style.contentPageContainer}>{data[0]?.titleCreditCard}</div>
        <div className={style.title}>{data[0]?.subTitleCreditCard.replace("<p>", "").replace("</p>", "")}</div>
      </>
    ) : window.location.href.includes("failbit") ? (
      <>
        <div className={style.contentPageContainer}>{data[0]?.titleFailBit}</div>
        <div className={style.title}>{data[0]?.subTitleFailBit.replace("<p>", "").replace("</p>", "")}</div>
      </>
    )
    : window.location.href.includes("successbit") ? (
      <>
        <div className={style.contentPageContainer}>{data[0]?.titleSuccessBit}</div>
        <div className={style.title}>{data[0]?.subTitleSuccessBit.replace("<p>", "").replace("</p>", "")}</div>
      </>
    ) : (
      <div>No matching case found</div>
    )}
  </div>
  );
};

const useStyles = makeStyles(theme => ({
  contentPageContainer: {
    textAlign: "center",
    fontSize: 40,
    color: "#457EC2",
  },
  title: {
    textAlign: "center",
    fontSize: 26,
    margin: "10rem 0 20rem 0",
    color: "#457EC2",
  },
}));

export default ThanksPage;
